import { ComponentFactory } from 'preact';

export type PageHandler<P = any> = () => Promise<ComponentFactory<P>>;

export const pageHandlerMap = {
	blogIndex: () => import('../components/blog.js').then(m => m.BlogIndexWrapper),
	blogPost: () => import('../components/blog.js').then(m => m.BlogPostWrapper),
	builder: () => import('../components/builder/builder.js').then(m => m.Builder),
	commissions: () => import('../components/commissions-page.js').then(m => m.CommissionsPage),
	createAccount: () => import('../components/create-account.js').then(m => m.CreateAccountForm),
	dashboard: () => import('../components/dashboard.js').then(m => m.DashboardPage),
	digitizeOrderItem: () => import('../components/digitize-order-item.js').then(m => m.DigitizeOrderItemPage),
	faq: () => import('../components/faq-page.js').then(m => m.FaqPage),
	form: () => import('../components/form-page.js').then(m => m.FormPage),
	giftcards: () => import('../components/giftcards.js').then(m => m.GiftcardOrderPage),
	joinLeague: () => import('../components/join-league.js').then(m => m.JoinLeaguePage),
	order: () => import('../components/order-standalone.js').then(m => m.StandaloneOrderPage),
	orderItemPreview: () => import('../components/order-item-preview.js').then(m => m.OrderItemPreviewPage),
	page: () => import('../components/page.js').then(m => m.PageWrapper),
	products: () => import('../components/products-page.js').then(m => m.ProductsPage),
	shoppingCart: () => import('../components/cart/cart.js').then(m => m.ShoppingCart),
	emptyCart: () => import('../components/cart/cart-empty.js').then(m => m.EmptyCartPlaceholder),
	auth: () => import('../components/auth.js').then(m => m.AuthForm),
	singleProduct: () => import('../components/single-product.js').then(m => m.SingleProductPage),
	store: () => import('../templates/pages/store.js').then(m => m.StorePage),
	storesPage: () => import('../templates/pages/stores.js').then(m => m.StoresPage),
	storeProductPage: () => import('../components/store-product.js').then(m => m.StoreProductPage),
	unsubscribe: () => import('../components/unsubscribe.js').then(m => m.Unsubscribe),
	icLanding: () => import('../components/inkcenter-ui.js').then(m => m.InkcenterLanding),
};

export type PageHandlerId = keyof typeof pageHandlerMap;

export const pageHandlers: {
	[key in PageHandlerId]: PageHandler
} = pageHandlerMap;
