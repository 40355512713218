import { initHotReload } from './lib/hot-reload.js';
import cookie from 'cookie';

import { OuterData } from '../common/pagedata.js';
import { PageHandler, pageHandlers } from './pages/pages.js';
import { Context } from '../common/context.js';

import { defaultLanguage, getTranslation, LanguageId, str } from '../common/i18n.js';
import { reportEventsFromServer, setUpTrackers } from './lib/events.js';
import { setContentUrlPrefix } from './lib/images.js';
import { ensure, maybe } from '../common/util.js';
import { createSiteContext, SiteBody, SiteData } from './templates/site.js';
import { initSentry } from './sentry.js';
import { h, render } from 'preact';
import { checkActiveAuthToken } from './lib/auth.js';
import { expandCart } from '../common/data/cart.js';

if (process.env.NODE_ENV==='development') {
	// Must use require here as import statements are only allowed
	// to exist at top-level.
	// require("preact/debug");
	require('preact/debug');
}

const data = JSON.parse(document.getElementById('data')!.textContent!) as OuterData;
if (!data.disableTracking) initSentry('https://fde0b9af65a04a2bb83b61d7e906b275@o306148.ingest.sentry.io/1764020');

const cookies = cookie.parse(document.cookie);
cookies.csrf = data.csrf!;

const lang = (cookies.lang || defaultLanguage) as LanguageId;

const { user, account, domain } = data;
const context: Context = {
	domain: data.domain,
	authHost: data.authHost,
	user,
	account,
	cookies,
	url: location.pathname + location.search,
	lang,
	t: getTranslation,
	str,
	resourceHashes: data.resourceHashes,
};
context.t = getTranslation.bind(context);
context.str = str.bind(context);

const initPage = async () => {
	
	const siteData = maybe(document.querySelector<HTMLScriptElement>('script#site-data'), el => JSON.parse(ensure(el.textContent)) as SiteData);
	if (!siteData) return;
	
	const siteContext = createSiteContext({
		...siteData,
		context,
		user,
		account,
		domain,
		cart: maybe(siteData.cart, expandCart),
	});
	
	const pageHandler = data.pageHandler && await ensure((pageHandlers as Record<string, PageHandler>)[data.pageHandler])();
	const { pageData } = data;
	
	const body = h(SiteBody, { siteContext, pageHandler, pageData });
	render(body, document.body);
	
	if (!user && context.authHost) checkActiveAuthToken(siteContext, context.authHost).catch(e => console.error(e));
	
};

if (data.contentUrlPrefix) setContentUrlPrefix(data.contentUrlPrefix);
initPage();

if (process.env.NODE_ENV === 'development') {
	const devConfig = data.devConfig;
	if (devConfig?.devSocketPort || devConfig?.devSocketPath) initHotReload(devConfig);
}

const { serverEvents } = data;
setTimeout(() => setUpTrackers(data), 1);
if (serverEvents?.length) setTimeout(() => reportEventsFromServer(serverEvents));
